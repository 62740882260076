const kvDuration = 1;

/** 初始化 KV slider */
function kvSwiper() {
  const imageSwiper = new Swiper('.swiper-homepage-kv-image', {
    speed: kvDuration * 1000,
    effect: 'fade',
    loop: true,
    navigation: {
      nextEl: '.swiper-homepage-kv-image .swiper-button-next',
      prevEl: '.swiper-homepage-kv-image .swiper-button-prev',
    },
  });

  const contentSwiper = new Swiper('.swiper-homepage-kv-content', {
    effect: 'fade',
    speed: kvDuration * 1000,
    autoHeight: true,
    loop: true,
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: '.swiper-homepage-kv-content .swiper-button-next',
      prevEl: '.swiper-homepage-kv-content .swiper-button-prev',
    },
    pagination: {
      el: '.swiper-homepage-kv-content .swiper-pagination',
      type: 'fraction',
      formatFractionCurrent(fractionNumber) {
        return fractionNumber.toString().padStart(2, '0');
      },
      formatFractionTotal(fractionNumber) {
        return fractionNumber.toString().padStart(2, '0');
      },
    },
  });

  imageSwiper.controller.control = contentSwiper;
  contentSwiper.controller.control = imageSwiper;
}

/** 初始化最新消息 slider */
function newsSwiper() {
  new Swiper('.swiper-homepage-news', {
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-homepage-news .swiper-button-next',
      prevEl: '.swiper-homepage-news .swiper-button-prev',
    },
    spaceBetween: 5,
    breakpoints: {
      // when window width is >= 1024px
      1024: {
        slidesPerView: 1.6,
        spaceBetween: 30,
      },
    },
  });
}

$(function () {
  kvSwiper();
  newsSwiper();
});
